export const enumData = {
  companyPlan: [
    { value: 0, label: 'プランA', tag: 'red' },
    { value: 1, label: 'プランB', tag: 'blue' },
    { value: 2, label: 'API プラン', tag: 'green' },
    { value: 3, label: '個人認証 プラン', tag: 'orange' },
    { value: 4, label: 'ロガー プラン', tag: 'cyan' },
    { value: 6, label: 'フリート プラン', tag: 'pink' },
    { value: 5, label: 'プランF', tag: 'purple' },
  ],
  userRole: [
    { value: 0, label: 'マスター', tag: 'red' },
    { value: 1, label: '会社管理者', tag: 'blue' },
    { value: 2, label: '支店管理者', tag: 'green' },
    { value: 3, label: 'ドライバー', tag: 'orange' },
  ],
  historyModel: [
    { value: 'Company', label: '会社', tag: 'orange' },
    { value: 'Branch', label: '支店', tag: 'green' },
    { value: 'User', label: 'ユーザー', tag: 'cyan' },
    { value: 'Vehicle', label: '車両', tag: 'blue' },
    { value: 'BranchUser', label: 'ユーザーの支店所属', tag: 'purple' },
    // pink, red,
  ],
  carType: [
    { value: 0, label: '車種を選択してください。' },
    { value: 25, label: '普車' },
    { value: 33, label: '普貨' },
    { value: 32, label: '準中貨' },
    { value: 30, label: '中貨' },
    { value: 31, label: '特定中貨' },
    { value: 28, label: '大貨' },
    { value: 29, label: '大貨等' },
    { value: 34, label: 'けん引' },
    { value: 11, label: '原付' },
    { value: 8, label: '自二輪' },
    { value: 14, label: '自転車' },
    { value: 26, label: 'タクシー' },
    { value: 22, label: '大型バス' },
    { value: 23, label: 'マイクロ' },
    { value: 24, label: '路線バス' },
    { value: 17, label: '大乗' },
    { value: 18, label: '中乗' },
    { value: 19, label: '特定中乗' },
    { value: 15, label: 'トロリー' },
    { value: 10, label: '小特' },
    { value: 7, label: '大特' },
    { value: 9, label: '軽' },
    { value: 27, label: '貨物' },
  ],
  violationType: [
    { value: 1, label: '速度超過(20 未満)' },
    { value: 2, label: '速度超過(20 以上 25 未満)' },
    { value: 3, label: '速度超過(25 以上 30 未満)' },
    { value: 4, label: '速度超過(30 以上 50 未満)' },
    { value: 5, label: '速度超過(50 以上)' },
    { value: 6, label: '指定場所一時不停止等' },
    { value: 7, label: '踏切不停止等' },
    { value: 8, label: '通行禁止違反(一方通行)' },
    { value: 9, label: '通行禁止違反(指定方向外進行禁止)' },
    // { value: 10, label: '駐停車違反' },
    // { value: 11, label: '停車禁止違反' },
    // { value: 12, label: '駐車禁止違反' },
  ],
  announcementType: [
    { value: '1-1', label: '先：一時停止' },
    { value: '1-2', label: '付近：一時停止' },
    { value: '2-1', label: '先：踏切' },
    { value: '2-2', label: '付近：踏切' },
    { value: '3-1', label: '速度規制変更' },
    { value: '4-1', label: '速度超過 (1~10)' },
    { value: '4-2', label: '速度超過 (10~20)' },
    { value: '4-3', label: '速度超過 (20~30)' },
    { value: '4-4', label: '速度超過 (30~40)' },
    { value: '4-5', label: '速度超過 (40~50)' },
    { value: '4-6', label: '速度超過 (50~)' },
    { value: '5-1', label: 'ゾーン30' },
    { value: '5-2', label: 'ゾーン30 速度超過' },
  ],
  roadType: [
    { value: 1, label: '高速自動車国道' },
    { value: 2, label: '都市高速道路' },
    { value: 3, label: '一般国道' },
    { value: 4, label: '主要地方道' },
    { value: 5, label: '主要地方道(指定市道)' },
    { value: 6, label: '一般都道府県道' },
    { value: 7, label: '主要一般道' },
    { value: 8, label: '一般道' },
    { value: 9, label: '細道路' },
  ],
  driversLisenceType: [
    { value: '1', label: '普通（第一種）' },
    { value: '2', label: '普通（第二種）' },
    { value: '3', label: '準中型' },
    { value: '4', label: '中型（第一種）' },
    { value: '5', label: '中型（第二種）' },
    { value: '6', label: '大型（第一種）' },
    { value: '7', label: '大型（第二種）' },
    { value: '8', label: '小型特殊' },
    { value: '9', label: '大型特殊（第一種）' },
    { value: '10', label: '大型特殊（第二種）' },
    { value: '11', label: '牽引（第一種）' },
    { value: '12', label: '牽引（第二種）' },
    { value: '13', label: '普通二輪' },
    { value: '14', label: '大型二輪' },
    { value: '15', label: '原付' },
  ],
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { class: _vm.$style.pageTitle }, [
        _vm._v("ユーザー 一覧 (" + _vm._s(_vm.users.length) + " 人)")
      ]),
      _c("section", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "pull-right mt-2" }, [
            _c(
              "div",
              { staticClass: "d-inline-block" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "userCreate" } } },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-3",
                        class: _vm.$style.filledBtn,
                        attrs: {
                          type: "primary",
                          autoInsertSpaceInButton: "false"
                        }
                      },
                      [_vm._v("作成")]
                    )
                  ],
                  1
                ),
                _c(
                  "router-link",
                  { attrs: { to: { name: "userCreateBulk" } } },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-3",
                        class: _vm.$style.filledBtn,
                        attrs: {
                          type: "primary",
                          autoInsertSpaceInButton: "false"
                        }
                      },
                      [_vm._v("一括作成")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _vm.userListLoading
            ? _c(
                "div",
                { staticClass: "text-center" },
                [_c("a-spin", { attrs: { tip: "Loading..." } })],
                1
              )
            : _vm._e(),
          !_vm.userListLoading
            ? _c(
                "div",
                [
                  _c(
                    "a-form",
                    { attrs: { layout: "inline" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "名前" } },
                        [
                          _c("form-multiple-select", {
                            staticStyle: { "min-width": "200px" },
                            attrs: {
                              placeholder: "名前",
                              candidates: _vm.users
                            },
                            on: { changeSelectedItems: _vm.changeSelectedName }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "権限" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { "min-width": "200px" },
                              attrs: {
                                mode: "multiple",
                                placeholder: "権限",
                                value: _vm.selectedRoles
                              },
                              on: { change: _vm.changeSelectedRole }
                            },
                            _vm._l(_vm.filteredOptions, function(item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.label,
                                  attrs: { value: item.label }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.label) +
                                      "\n              "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "所属支店" } },
                        [
                          _c("form-multiple-select", {
                            staticStyle: { "min-width": "200px" },
                            attrs: {
                              placeholder: "所属支店",
                              candidates: _vm.branches
                            },
                            on: {
                              changeSelectedItems: _vm.changeSelectedBranch
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.hasMasterRole
                    ? _c("div", { staticClass: "mt-3 mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "d-inline-block" },
                          [
                            _c(
                              "a-button",
                              {
                                class: _vm.$style.filledBtn,
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.isSelectionalCommandDisabled,
                                  loading: _vm.btnLoading
                                },
                                on: {
                                  click: function($event) {
                                    _vm.bulkUpdateModalVisible = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              一括処理する\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "a-table",
                    {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.filteredUsers,
                        pagination: false,
                        rowKey: "id",
                        rowClassName: function(r, i) {
                          return i % 2 === 0
                            ? _vm.$style.stripe0
                            : _vm.$style.stripe1
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(_, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c("a-checkbox", {
                                    attrs: {
                                      checked: _vm.isRowSelected(record.id)
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onChangeRowSelection(
                                          $event,
                                          record.id
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "name",
                            fn: function(name, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _vm.myRole === 0
                                    ? _c("span", [
                                        _vm._v(_vm._s(record.id) + ": ")
                                      ])
                                    : _vm._e(),
                                  record.unavailable === 1
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "a-tag",
                                            { attrs: { color: "red" } },
                                            [_vm._v("停止中")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "userShow",
                                          params: { id: record.id }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(name))]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "type_icon",
                            fn: function(type_icon, record) {
                              return _c("span", {}, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "18px",
                                      display: "inline-block"
                                    }
                                  },
                                  [
                                    record.is_google2fa === 1
                                      ? _c("i", {
                                          staticClass: "icmn-qrcode",
                                          staticStyle: { color: "#388E3C" }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _vm.showUserFleetFlag
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          width: "18px",
                                          display: "inline-block"
                                        }
                                      },
                                      [
                                        record.fleet_flag === 0
                                          ? _c("i", {
                                              staticClass: "fa fa-tachometer",
                                              staticStyle: { color: "#42a2e9" }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.showUserRollCallAgent
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          width: "18px",
                                          display: "inline-block"
                                        }
                                      },
                                      [
                                        record.roll_call_agent === 1
                                          ? _c("i", {
                                              staticClass:
                                                "fa fa fa-phone-square",
                                              staticStyle: { color: "#fa8c16" }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "18px",
                                      display: "inline-block"
                                    }
                                  },
                                  [
                                    record.plus_flag === 1
                                      ? _c("i", {
                                          staticClass: "fa fa-plus",
                                          staticStyle: { color: "#a11c3f" }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "18px",
                                      display: "inline-block"
                                    }
                                  },
                                  [
                                    record.allow_driving_schedule === 1
                                      ? _c("i", {
                                          staticClass: "fa fa-calendar-plus-o",
                                          staticStyle: { color: "#4b0082" }
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "18px",
                                      display: "inline-block"
                                    }
                                  },
                                  [
                                    record.interlocking_detector === 1
                                      ? _c("i", {
                                          staticClass: "fa fa-bluetooth",
                                          staticStyle: { color: "#146DAD" }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ])
                            }
                          },
                          {
                            key: "role",
                            fn: function(role) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c(
                                    "a-tag",
                                    { attrs: { color: _vm.roles[role].color } },
                                    [_vm._v(_vm._s(_vm.roles[role].label))]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "confirmation",
                            fn: function(confirmation) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(confirmation))
                              ])
                            }
                          },
                          {
                            key: "lastLogin",
                            fn: function(lastLogin) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("moment")(lastLogin)))
                              ])
                            }
                          },
                          {
                            key: "branch",
                            fn: function(branch, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c(
                                    "a-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.showBranchModal(record)
                                        }
                                      }
                                    },
                                    [_vm._v("所属")]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "roll_call_remain",
                            fn: function(roll_call_remain, record) {
                              return _c("span", {}, [
                                _vm._v(
                                  _vm._s(
                                    record.roll_call_count -
                                      record.count_roll_call_agent
                                  )
                                )
                              ])
                            }
                          },
                          {
                            key: "roll_call_count",
                            fn: function(roll_call_count, record) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(record.roll_call_count))
                              ])
                            }
                          }
                        ],
                        null,
                        false,
                        15725134
                      )
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: { slot: "selectionTitle" },
                          slot: "selectionTitle"
                        },
                        [
                          _c("a-checkbox", {
                            attrs: {
                              indeterminate: _vm.isAllSelectionIndeterminate,
                              checked: _vm.isAllSelected
                            },
                            on: { change: _vm.onChangeAllSelection }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c("p", [
                    _vm._v("\n          種別\n          "),
                    _vm._m(0),
                    _vm.showUserFleetFlag
                      ? _c("span", [
                          _c("br"),
                          _c("i", {
                            staticClass: "fa fa-tachometer",
                            staticStyle: { color: "#42a2e9" }
                          }),
                          _vm._v(": 解析対象")
                        ])
                      : _vm._e(),
                    _vm.showUserRollCallAgent
                      ? _c("span", [
                          _c("br"),
                          _c("i", {
                            staticClass: "fa fa fa-phone-square",
                            staticStyle: { color: "#fa8c16" }
                          }),
                          _vm._v(": 点呼代行対象")
                        ])
                      : _vm._e(),
                    _vm._m(1),
                    _vm._m(2),
                    _vm._m(3)
                  ]),
                  _c(
                    "p",
                    { staticClass: "pull-right" },
                    [
                      _c(
                        "a-button",
                        {
                          class: _vm.$style.filledBtn,
                          staticStyle: { "margin-right": "10px" },
                          on: { click: _vm.exportCsv }
                        },
                        [_vm._v("CSV 出力")]
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "driversLisenceCreateBulk" } } },
                        [
                          _c(
                            "a-button",
                            {
                              class: _vm.$style.filledBtn,
                              attrs: {
                                type: "primary",
                                autoInsertSpaceInButton: "false"
                              }
                            },
                            [_vm._v("運転免許一括作成")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            visible: _vm.modalVisible,
            footer: null
          },
          on: { cancel: _vm.hideModal }
        },
        [
          _c(
            "ul",
            { staticClass: "user_branches" },
            _vm._l(_vm.userBranches, function(branch) {
              return _c("li", { key: branch.id }, [
                _vm._v("\n        " + _vm._s(branch.name) + "\n      ")
              ])
            }),
            0
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "チェックされたユーザーの一括処理",
            visible: _vm.bulkUpdateModalVisible,
            footer: null
          },
          on: {
            cancel: function($event) {
              _vm.bulkUpdateModalVisible = false
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("fleet_flag", 0)
                    }
                  }
                },
                [_vm._v("\n        解析対象にする\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("fleet_flag", 1)
                    }
                  }
                },
                [_vm._v("\n        解析対象にしない\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("allow_driving_schedule", 1)
                    }
                  }
                },
                [_vm._v("\n        運行計画表の被閲覧を許可する\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("allow_driving_schedule", 0)
                    }
                  }
                },
                [_vm._v("\n        運行計画表の被閲覧を許可しない\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("plus_flag", 1)
                    }
                  }
                },
                [_vm._v("\n        PLUSを使用する\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("plus_flag", 0)
                    }
                  }
                },
                [_vm._v("\n        PLUSを使用しない\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("unavailable", 0)
                    }
                  }
                },
                [_vm._v("\n        アカウントを使用可能にする\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("unavailable", 1)
                    }
                  }
                },
                [_vm._v("\n        アカウントを使用不可にする\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("interlocking_detector", 1)
                    }
                  }
                },
                [_vm._v("\n        連動検知器を使用にする\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-button",
                {
                  class: _vm.$style.filledBtn,
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function($event) {
                      return _vm.updateFlag("interlocking_detector", 0)
                    }
                  }
                },
                [_vm._v("\n        連動検知器を非使用にする\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "a-form-model",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-form-model-item",
                    { ref: "number", attrs: { label: "値", prop: "number" } },
                    [
                      _c("a-input-number", {
                        attrs: { min: -100, max: 100 },
                        model: {
                          value: _vm.number,
                          callback: function($$v) {
                            _vm.number = $$v
                          },
                          expression: "number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      class: _vm.$style.filledBtn,
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function($event) {
                          return _vm.updateFlag("roll_call_count", 0)
                        }
                      }
                    },
                    [_vm._v("\n          契約コール数を増減する\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("br"),
      _c("i", {
        staticClass: "icmn-qrcode",
        staticStyle: { color: "#388E3C" }
      }),
      _vm._v(": 2段階認証")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("br"),
      _c("i", { staticClass: "fa fa-plus", staticStyle: { color: "#a11c3f" } }),
      _vm._v(": PLUS の使用")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("br"),
      _c("i", {
        staticClass: "fa fa-calendar-plus-o",
        staticStyle: { color: "#4b0082" }
      }),
      _vm._v(": 運行計画表の被閲覧許可")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("br"),
      _c("i", {
        staticClass: "fa fa-bluetooth",
        staticStyle: { color: "#146DAD" }
      }),
      _vm._v(": 連動検知器の使用")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }